import './app.css';
import './theme.css';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store, history} from './store';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import App from './app';
import Modal from 'react-modal';

Modal.setAppElement('#modal');

history.listen((location, action) => {
  if(location.pathname !== '/' && location.pathname !== '/clara-ukazka' && location.pathname !== '/expo') {
    window.scrollTo(0, 0);
  }
});

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter store={store} history={history}>
      <Switch>
        <Route path='/' component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
), document.getElementById('root'));
